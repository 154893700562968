<div [class]="organization() === 'abpm' ? 'abpm' : ''" class="modal-body alert-modal info">
  
  <div class="d-flex flex-column p-1 content-body">
    <div class="d-flex justify-content-end p-1 mb-2">
      <button [class]="organization() === 'abpm' ? 'abpm' : ''" class="btn btn-outline-primary clickable" (click)="close()">
        <fa-icon [icon]="faXmark"></fa-icon>
        Close
      </button>
    </div>

    <!-- Generic vague message where we don't know the organization. Should not happen? -->
    <ng-container *ngIf="!organization()">
      Contact Learnswell support
    </ng-container>

    <ng-container *ngIf="organization()">

      <ng-container [ngSwitch]="organization()">

        <ng-container *ngSwitchCase="'silkoak'">Message us on Facebook</ng-container>
        <ng-container *ngSwitchCase="'silkoak-bn'">Message us on Facebook</ng-container>

        <ng-container *ngSwitchCase="'abfas'">

          <p>
            For questions and assistance related to certification or program requirement expectations
            including MDT p-value and other program specifics, as well as technical issues, please reach out to <a
            href="mailto:lead&#64;abfas.org">lead&#64;abfas.org</a>.
          </p>

        </ng-container>

        <ng-container *ngSwitchCase="'abpm'">
          <p class="abpm">Please reach out to <a href="mailto:abpm&#64;theabpm.org">abpm&#64;theabpm.org</a> for assistance.
        </ng-container>

        <ng-container *ngSwitchDefault>
          Contact Learnswell Support
        </ng-container>

      </ng-container>

    </ng-container>
  </div>

</div>
