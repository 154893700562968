import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ApplicationStateService } from '../services/application-state.service';
import { UserComponent } from './user/user.component';
import { ContactSupportModalComponent } from '../contact-support-modal/contact-support-modal.component';

const LOGO = new Map([
  ['nbcrna', 'cpca.png'],
  ['silkoak', ''],
  ['silkoak-bn', ''],
  ['iars-eeg', 'eeg.svg'],
  ['abpm', 'abpm.ico'],
  ['abfas', 'abfas.ico']
]);

@Component({
  selector: 'portal-header',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, UserComponent, ContactSupportModalComponent],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  protected organizationLogoFile: string = '';
  protected organization: string | undefined;

  constructor(
    protected appState: ApplicationStateService,
    private ngbModal: NgbModal
  ) { }

  ngOnInit(): void {
    // improve this
    this.appState.subscribe(state => state.organization)
      .pipe(
        tap(organization => this.organization = organization)
      )
      .subscribe();
  }

  getLogo(): string {
    return LOGO.get(this.organization!) ?? 'learnswell.svg';
  }

  openSupport() {
    this.ngbModal.open(ContactSupportModalComponent, { centered: true, backdrop: 'static' });
  }
}
