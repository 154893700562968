<div id="header-wrap" [ngClass]="organization" class="d-flex justify-content-between align-items-center ">
  <div id="brand" *ngIf="organization">
    <div class="logo">
      <img [src]="'assets/header-logos/' + getLogo()">
    </div>
  </div>

  <div id="support" class="btn btn-outline-primary clickable" (click)="openSupport()">
    Get Help
  </div>


  <div id="user" class="">
    <portal-user/>
  </div>


</div>

