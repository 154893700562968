import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ApplicationStateService } from '../services/application-state.service';

@Component({
  selector: 'portal-contact-support-modal',
  standalone: true,
  imports: [CommonModule, FaIconComponent],
  templateUrl: './contact-support-modal.component.html',
  styleUrl: './contact-support-modal.component.scss'
})
export class ContactSupportModalComponent {

  protected readonly faXmark = faXmark;
  protected organization = toSignal(this.appState.subscribe(state => state.organization));

  constructor(
    private activeModal: NgbActiveModal,
    private appState: ApplicationStateService
  ) {
  }

  close(): void {
    this.activeModal.close();
  }
}
